import { LOCALE, TIME_ZONE } from './environment'

function formatYearDayAndMonth(date: Date): string {
  return date.toLocaleString(LOCALE, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZone: TIME_ZONE,
  })
}

function formatHourAndMinutes(date: Date): string {
  return date.toLocaleString(LOCALE, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: TIME_ZONE,
  })
}

export function formatLocaleDate(date: Date): string {
  return `${formatYearDayAndMonth(date)} ${formatHourAndMinutes(date)}`
}
