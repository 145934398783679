import React from 'react'
import { formatLocaleDate } from '../lib/dates'

type ColoredDateProps = {
  date: ?String,
  yellowMaxAgeDays?: number,
  redMaxAgeDays?: number,
}

export function ColoredDate({ date, redMaxAgeDays = 3, yellowMaxAgeDays = 10 }: ColoredDateProps) {
  const millisInDay = 24 * 60 * 60 * 1000
  let diff = new Date() - new Date(date)

  let statusClass
  if (diff <= redMaxAgeDays * millisInDay) {
    statusClass = 'text-red font-bold'
  } else if (diff <= yellowMaxAgeDays * millisInDay) {
    statusClass = 'text-orange'
  } else {
    statusClass = 'text-green'
  }

  return <span className={statusClass}>{date ? formatLocaleDate(new Date(date)) : '-'}</span>
}
