import React from 'react'
import { ExitIcon } from './icons'
import tomraArrow from '../images/tomra-arrow.png'

import type { Node } from 'react'
import { AppBarDiv } from './AppBarDiv'

type HeaderProps = {
  children?: Node,
  doLogout: Function,
}

export const Header = ({ doLogout }: HeaderProps) => {
  return (
    <div className="navbar">
      <div className="flex items-center">
        <img src={tomraArrow} className="h-xl" alt="TOMRA logo" />
        <h2 className="ml-md">Report Manager</h2>
      </div>

      <div className="flex">
        <AppBarDiv />
        <button className="btn btn-icon" onClick={doLogout}>
          <ExitIcon color="white" />
        </button>
      </div>
    </div>
  )
}

export default Header
