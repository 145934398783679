import React from 'react'
import { ENV_NAME } from '../lib/environment'
import { authentication } from '../lib/authentication'

type Props = {}

export class AppBarDiv extends React.Component<Props> {
  componentDidMount() {
    window.tomraMenu.mount(authentication.getToken())
  }

  render() {
    return <div id="menu-root" data-env={ENV_NAME} />
  }
}
