import React from 'react'
import { ReportRow } from './ReportRow'

import type { Report } from '../dataTypes'

function renderReports(reports: Array<Report>) {
  return reports.map((report) => <ReportRow report={report} key={report.id} />)
}

type Props = {
  reports: Array<Report | Object>, // Object to satisfy flow
}

export const ReportsPage = (props: Props) => (
  <div className="card">
    <table className="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Clearinghouse</th>
          <th>Report type</th>
          <th>Recipients</th>
          <th>Frequency</th>
          <th>Status</th>
          <th>Last failure</th>
          <th>Date and time</th>
          <th>Last event</th>
          <th>Next run</th>
        </tr>
      </thead>
      <tbody>{renderReports(props.reports)}</tbody>
    </table>
  </div>
)

export default ReportsPage
