import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
import { configure } from 'mobx'

import App from './App'
import { authentication } from './lib/authentication'
import { ReportsDataStore } from './stores'

configure({ enforceActions: 'always' })

const reportsDataStore = new ReportsDataStore()

authentication.init().then(
  () => {
    const rootEl = document.getElementById('root')
    if (rootEl === null) {
      alert('Application failed to start!')
      throw new Error('Root element missing!')
    }
    const reactRoot = ReactDOM.createRoot(rootEl)
    reactRoot.render(
      <Provider reportsDataStore={reportsDataStore}>
        <Router>
          <App doLogout={authentication.logout} />
        </Router>
      </Provider>
    )
  },
  () => {
    authentication.login()
  }
)
