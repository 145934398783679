import React from 'react'
import { LeftArrowIcon } from './icons'

import type { Report, ReportDetails } from '../dataTypes'
import ReportDeliveryDetails from './ReportDeliveryDetails'
import ReportDeliveryEvents from './ReportDeliveryEvents'

type Props = {
  reportDefinition: Report,
  reportId: number,
  fetchReportDeliveryDetails: Function,
  reportDeliveryDetails: ReportDetails,
  retryReport: Function,
  retryFailedReportDeliveries: Function,
  retryFailedPartDelivery: Function,
  skipFailedPartDelivery: Function,
  loadingReportDeliveryDetails: 'loading' | 'failed' | '',
}

type State = {
  isRetrying: boolean,
}

export class ReportDeliveryDetailPage extends React.Component<Props, State> {
  state = { isRetrying: false }

  componentDidMount() {
    const { reportId, fetchReportDeliveryDetails } = this.props
    fetchReportDeliveryDetails(reportId)
  }

  render() {
    const {
      reportDefinition,
      reportDeliveryDetails,
      loadingReportDeliveryDetails,
      retryReport,
      retryFailedReportDeliveries,
      retryFailedPartDelivery,
      skipFailedPartDelivery,
    } = this.props

    const isLoadingDetails = loadingReportDeliveryDetails === 'loading'
    const isReportProcessing =
      this.state.isRetrying || ['GENERATING', 'GENERATED', 'DELIVERING'].includes(reportDeliveryDetails.report?.status)

    const setRetrying = () => this.setState({ isRetrying: true })

    return (
      <div>
        <div className="flex items-center mb-md">
          <LeftArrowIcon color="var(--colors-blue)" />
          <a className="link" href={`/report/${reportDefinition.id}`}>
            <h2>Report Details</h2>
          </a>
        </div>
        <div className="space-y-md">
          {isLoadingDetails ? (
            <div className="loadingSpinner centerAbsolute" />
          ) : (
            <>
              <ReportDeliveryDetails
                reportName={reportDefinition.name}
                reportDeliveryDetails={reportDeliveryDetails.report}
                isReportProcessing={isReportProcessing}
                retryReport={retryReport}
                retryFailedReportDeliveries={retryFailedReportDeliveries}
                onRetry={setRetrying}
              />
              <ReportDeliveryEvents
                deliveryEvents={reportDeliveryDetails.deliveryEvents}
                retryFailedPartDelivery={retryFailedPartDelivery}
                skipFailedPartDelivery={skipFailedPartDelivery}
                isReportProcessing={isReportProcessing}
                onPartDeliveryRetry={setRetrying}
              />
            </>
          )}
        </div>
      </div>
    )
  }
}

export default ReportDeliveryDetailPage
