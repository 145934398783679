import React, { useState } from 'react'

import type { DeliveryEvent } from '../dataTypes'
import { ReportDeliveryEventRow } from './ReportDeliveryEventRow'

type Props = {
  deliveryEvents: Array<DeliveryEvent>,
  retryFailedPartDelivery: Function,
  skipFailedPartDelivery: Function,
  isReportProcessing: boolean,
  onPartDeliveryRetry: Function,
}

export const ReportDeliveryEvents = (props: Props) => {
  const { deliveryEvents, retryFailedPartDelivery, skipFailedPartDelivery, isReportProcessing, onPartDeliveryRetry } =
    props
  const [showStateSelector, setShowStateSelector] = useState(false)
  const [allStatuses] = useState(Array.from(new Set(deliveryEvents.map((d) => d.status) || [])))
  const [visibleStatuses, setVisibleStatuses] = useState(allStatuses)

  function toggleVisibleStatus(status: string) {
    setVisibleStatuses(
      visibleStatuses.includes(status) ? visibleStatuses.filter((s) => s !== status) : visibleStatuses.concat([status])
    )
  }
  return (
    <div className="card">
      {deliveryEvents.length === 0 ? (
        <h2 className="p-md">No delivery events available for this report</h2>
      ) : (
        <div>
          <h2 className="text-lg p-md">Delivery events</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Part ID</th>
                <th>Last event</th>
                <th>
                  <button onClick={() => setShowStateSelector(!showStateSelector)}>
                    <span className="link">Status</span>
                  </button>
                  {showStateSelector && (
                    <div className="card p-md" style={{ position: 'absolute', zIndex: 999, top: 0, left: 50 }}>
                      {allStatuses.map((status) => (
                        <div key={status} onClick={() => toggleVisibleStatus(status)}>
                          <input
                            className="mr-sm"
                            type={'checkbox'}
                            onChange={() => toggleVisibleStatus(status)}
                            onClick={(e) => e.stopPropagation()}
                            checked={visibleStatuses.includes(status)}
                          />
                          {status}
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th>Details</th>
                <th>File</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {deliveryEvents
                .filter((event) => visibleStatuses.includes(event.status))
                .sort((a, b) => fromStatusType(a.status) - fromStatusType(b.status))
                .map((event) => (
                  <ReportDeliveryEventRow
                    event={event}
                    key={event.eventId}
                    retryFailedPartDelivery={retryFailedPartDelivery}
                    skipFailedPartDelivery={skipFailedPartDelivery}
                    isReportProcessing={isReportProcessing}
                    onPartDeliveryRetry={onPartDeliveryRetry}
                  />
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

const StatusSortOrder = {
  GENERATION_FAILED: 1,
  DELIVERY_FAILED: 2,
  DELIVERY_SKIPPED: 3,
  GENERATING: 4,
  GENERATED: 5,
  DELIVERING: 6,
  DELIVERED: 7,
}

function fromStatusType(status: String): number {
  return StatusSortOrder[status] || 99
}

export default ReportDeliveryEvents
