import React from 'react'
import { inject, observer } from 'mobx-react'
import { Route, Routes } from 'react-router-dom'
import type { ReportsDataStore } from '../stores'
import ReportsPage from './ReportsPage'
import ReportDeliveryDetailPageWrapper from './ReportDeliveryDetailPageWrapper'
import ReportDetailPageWrapper from './ReportDetailPageWrapper'

type Props = {
  reportsDataStore: ReportsDataStore,
}

export class ReportsPageContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.reportsDataStore.fetchReports()
  }

  render() {
    const { loadingReportsStatus, reports } = this.props.reportsDataStore

    if (loadingReportsStatus === 'loading') {
      return <div className="loadingSpinner centerAbsolute" />
    }

    if (loadingReportsStatus === 'failed') {
      return (
        <div className="centerAbsolute">
          <div className="alert alert-danger">Uh-oh.. Failed to load reports</div>
        </div>
      )
    }

    if (reports.length === 0) {
      return (
        <div className="centerAbsolute">
          <div className="alert alert-warning">There are no reports yet.</div>
        </div>
      )
    }

    return (
      <Routes>
        <Route path="/" element={<ReportsPage reports={reports} />} />
        <Route path="/report/:definitionId/:reportId" element={<ReportDeliveryDetailPageWrapper />} />
        <Route path="/report/:id" element={<ReportDetailPageWrapper />} />
      </Routes>
    )
  }
}

export default inject('reportsDataStore')(observer(ReportsPageContainer))
