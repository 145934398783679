import localforage from 'localforage' // ... or any other web storage library
import { KEYCLOAK_HOST, LOCALE } from './environment'
import { AuthStore, WebStorage } from '@tomra/client-side-authentication'

const storage: WebStorage = {
  setItem: localforage.setItem,
  removeItem: localforage.removeItem,
  clear: localforage.clear,
  getItem: localforage.getItem,
}

export const authentication = new AuthStore('TomraConnectUsers', 'report-manager-ui', KEYCLOAK_HOST, LOCALE, storage)
