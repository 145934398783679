import React from 'react'
import { formatLocaleDate } from '../lib/dates'
import type { Report } from '../dataTypes'
import ColoredStatus from './ColoredStatus'
import { hasExceededThreshold } from './utils'
import { ColoredDate } from './ColoredDate'
import { useNavigate } from 'react-router-dom'

type Props = {
  report: Report,
}

export const ReportRow = ({ report }: Props) => {
  const navigate = useNavigate()
  return (
    <tr onClick={() => navigate(`../report/${report.id}`, { replace: true })}>
      <td>{report.name}</td>
      <td>{report.clearingHouse}</td>
      <td>{report.reportType}</td>
      <td>
        {report.recipients.map((recipient) => (
          <div key={recipient}>{recipient}</div>
        ))}
      </td>
      <td>{report.frequency}</td>
      <td>
        <ColoredStatus status={report.lastStatus} exceededTimeout={hasExceededThreshold(report.lastRun)} />
      </td>
      <td>
        <ColoredDate date={report.lastFailure} />
      </td>
      <td>{report.periodDefinition}</td>
      <td>
        <b>
          <a href={report.lastRunReportUrl} title="Click to download this report">
            {report.lastRun && formatLocaleDate(new Date(report.lastRun))}
          </a>
        </b>
      </td>
      <td>{formatLocaleDate(new Date(report.nextRun))}</td>
    </tr>
  )
}
