import React, { Component } from 'react'

import ReportsPageContainer from './components/ReportsPageContainer'
import Header from './components/Header'
import { VERSION } from './lib/environment'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  doLogout: Function,
}

class App extends Component<Props> {
  render() {
    return (
      <div>
        <Header doLogout={this.props.doLogout} />

        <div className="m-md">
          <ReportsPageContainer />
          <div>{VERSION}</div>
        </div>
        <ToastContainer />
      </div>
    )
  }
}

export default App
