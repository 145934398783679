import { useParams } from 'react-router-dom'
import ReportDetailPage from './ReportDetailPage'
import { inject, observer } from 'mobx-react'

const ReportDetailPageWrapper = ({ reportsDataStore }) => {
  const {
    reports,
    fetchReportHistory,
    loadingReportHistoryStatus,
    reportHistory,
    retryReport,
    retryFailedReportDeliveries,
  } = reportsDataStore
  const { id } = useParams()
  const report = reports.find((report) => report.id.toString() === id)
  if (!report) {
    return (
      <div className="centerAbsolute">
        <div className="alert alert-warning">This report does not exist.</div>
      </div>
    )
  }
  return (
    <ReportDetailPage
      report={report}
      fetchReportHistory={fetchReportHistory}
      reportHistory={reportHistory}
      retryReport={retryReport}
      retryFailedReportDeliveries={retryFailedReportDeliveries}
      loadingReportHistoryStatus={loadingReportHistoryStatus}
    />
  )
}

export default inject('reportsDataStore')(observer(ReportDetailPageWrapper))
