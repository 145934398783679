import React from 'react'
import ReportDetails from './ReportDetails'
import ReportHistory from './ReportHistory'
import { LeftArrowIcon } from './icons'

import type { Report, ReportHistory as ReportHistoryType } from '../dataTypes'

type Props = {
  report: Report,
  fetchReportHistory: Function,
  reportHistory: Array<ReportHistoryType>,
  retryReport: Function,
  retryFailedReportDeliveries: Function,
  loadingReportHistoryStatus: 'loading' | 'failed' | '',
}

export class ReportDetailPage extends React.Component<Props> {
  componentDidMount() {
    const { report, fetchReportHistory } = this.props
    fetchReportHistory(report.id)
  }

  render() {
    const { report, reportHistory, retryReport, retryFailedReportDeliveries, loadingReportHistoryStatus } = this.props

    const isLoadingHistory = loadingReportHistoryStatus === 'loading'

    return (
      <div>
        <div className="flex items-center mb-md">
          <LeftArrowIcon color="var(--colors-blue)" />
          <a className="link" href="/">
            <h2>Report Details</h2>
          </a>
        </div>
        <div className="space-y-md">
          <ReportDetails report={report} />
          {isLoadingHistory ? (
            <div className="loadingSpinner centerAbsolute" />
          ) : (
            <ReportHistory
              reportHistory={reportHistory}
              retryReport={retryReport}
              retryFailedReportDeliveries={retryFailedReportDeliveries}
              definitionId={report.id}
            />
          )}
        </div>
      </div>
    )
  }
}

export default ReportDetailPage
