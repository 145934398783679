import React from 'react'

type DownloadIconProps = {
  color?: string,
  size?: string,
  className?: string,
}

export const DownloadIcon = (props: DownloadIconProps) => (
  <svg
    fill={props.color || 'var(--colors-black)'}
    height={props.size || '36'}
    viewBox="0 0 24 24"
    width={props.size || '36'}
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

export default DownloadIcon
