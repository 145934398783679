import React from 'react'
import ReportHistoryRow from './ReportHistoryRow'

import type { ReportHistory as ReportHistoryType } from '../dataTypes'

type Props = {
  definitionId: number,
  reportHistory: Array<ReportHistoryType>,
  retryReport: Function,
  retryFailedReportDeliveries: Function,
}

export const ReportHistory = (props: Props) => (
  <div className="card">
    {props.reportHistory.length === 0 ? (
      <h2 className="p-md">No history available for this report</h2>
    ) : (
      <div>
        <h2 className="text-lg p-md">History</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Report ID</th>
              <th>From</th>
              <th>To</th>
              <th>Last event</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {props.reportHistory.map((history) => (
              <ReportHistoryRow
                history={history}
                retryReport={props.retryReport}
                retryFailedReportDeliveries={props.retryFailedReportDeliveries}
                definitionId={props.definitionId}
                key={history.reportId}
              />
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
)

export default ReportHistory
