import React from 'react'

import type { DeliveryEvent } from '../dataTypes'
import { formatLocaleDate } from '../lib/dates'
import ColoredStatus from './ColoredStatus'
import { DownloadIcon } from './icons/DownloadIcon'
import { hasExceededThreshold } from './utils'

type Props = {
  event: DeliveryEvent,
  retryFailedPartDelivery: Function,
  skipFailedPartDelivery: Function,
  isReportProcessing: boolean,
  onPartDeliveryRetry: Function,
}

type State = {
  isRetrying: boolean,
}

export class ReportDeliveryEventRow extends React.Component<Props, State> {
  state = { isRetrying: false }

  render() {
    const { reportId, partId, status, statusDetails, createdAt, fileUrl } = this.props.event
    const { retryFailedPartDelivery, skipFailedPartDelivery, isReportProcessing, onPartDeliveryRetry } = this.props

    const isExceededTimeout = hasExceededThreshold(createdAt)
    const isProcessing = this.state.isRetrying || ('DELIVERING' === status && !isExceededTimeout)
    const isFailed = 'DELIVERY_FAILED' === status || ('DELIVERING' === status && isExceededTimeout)

    return (
      <tr>
        <td>{partId}</td>
        <td>{formatLocaleDate(new Date(createdAt))}</td>
        <td>
          <ColoredStatus status={status} exceededTimeout={isExceededTimeout} />
        </td>
        <td>
          <code>{statusDetails}</code>
        </td>
        <td>
          <a href={fileUrl}>
            <DownloadIcon className="inline-block" color="var(--colors-green)" size="25" />
          </a>
        </td>
        <td>
          {isProcessing && <div className="loadingSpinner" />}
          {!isReportProcessing && !isProcessing && isFailed && (
            <div className="space-x-sm">
              <button
                className="btn btn-primary inline-block"
                onClick={() => {
                  onPartDeliveryRetry()
                  this.setState({ isRetrying: true })
                  retryFailedPartDelivery(reportId, partId)
                }}
              >
                Retry delivery
              </button>
              <button
                className="btn btn-secondary inline-block"
                onClick={() => {
                  onPartDeliveryRetry()
                  this.setState({ isRetrying: true })
                  skipFailedPartDelivery(reportId, partId)
                }}
              >
                Skip delivery
              </button>
            </div>
          )}
        </td>
      </tr>
    )
  }
}
