import React from 'react'

type ColoredStatusProps = {
  status: ?string,
  exceededTimeout: boolean,
}

export function ColoredStatus(props: ColoredStatusProps) {
  const { status, exceededTimeout } = props
  let statusClass
  if (status === 'DELIVERY_FAILED') {
    statusClass = 'text-red italic'
  } else if (status === 'GENERATION_FAILED') {
    statusClass = 'text-red'
  } else if (status === 'GENERATING' && exceededTimeout) {
    statusClass = 'text-orange'
  } else if (status === 'GENERATING' || status === 'DELIVERING') {
    statusClass = 'text-blue-light'
  } else if (status === 'GENERATED') {
    statusClass = 'text-green-light'
  } else if (status === 'DELIVERED') {
    statusClass = 'text-green'
  }

  return <span className={statusClass}>{status}</span>
}

export default ColoredStatus
