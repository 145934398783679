import { authentication } from './authentication'
import { fetch } from '@tomra/client-side-http-client'
import { toast } from 'react-toastify'

export function authenticatedFetch(url, options) {
  return fetch(url, options, authentication)
    .run()
    .catch((error) => {
      // Logout if refresh token is expired
      if (error.status === 401) {
        authentication.logout()
      }
      else if (error.status === 403) {
        toast('You do not have permission to perform this action', { type: 'error' })
      }
      else if (error.status === 500) {
        toast('An error occurred while processing your request', { type: 'error' })
      }
      throw error
    })
}
