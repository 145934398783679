import { action, extendObservable } from 'mobx'

import * as reportsService from '../services'

import type { Report, ReportDetails, ReportHistory } from '../dataTypes'

export class ReportsDataStore {
  reports: Array<Report>
  loadingReportsStatus: 'loading' | 'failed' | ''
  fetchReports: Function
  _reportSuccess: Function
  _reportFailed: Function

  reportHistory: Array<ReportHistory>
  loadingReportHistoryStatus: 'loading' | 'failed' | ''
  fetchReportHistory: Function
  _reportHistorySuccess: Function
  _reportHistoryFailed: Function

  reportDeliveryDetails: ReportDetails
  loadingReportDeliveryDetails: 'loading' | 'failed' | ''
  fetchReportDeliveryDetails: Function
  _reportDeliveryDetailsSuccess: Function
  _reportDeliveryDetailsFailed: Function

  retryReport: Function
  retryFailedReportDeliveries: Function
  retryFailedPartDelivery: Function
  skipFailedPartDelivery: Function

  constructor() {
    extendObservable(this, {
      reports: [],
      loadingReportsStatus: '',

      reportHistory: [],
      loadingReportHistoryStatus: '',

      reportDeliveryDetails: {},
      loadingReportDeliveryDetails: 'loading',

      fetchReports: action(() => {
        this.loadingReportsStatus = 'loading'

        return reportsService.fetchReports().then(this._reportSuccess, this._reportFailed)
      }),

      _reportSuccess: action((reports: Report[]) => {
        this.reports = reports
        this.loadingReportsStatus = ''
      }),

      _reportFailed: action((error) => {
        this.loadingReportsStatus = 'failed'
      }),

      fetchReportHistory: action((reportId: number) => {
        this.loadingReportHistoryStatus = 'loading'

        return reportsService.fetchReportHistory(reportId).then(this._reportHistorySuccess, this._reportHistoryFailed)
      }),

      _reportHistorySuccess: action((reportHistory: ReportHistory[]) => {
        this.reportHistory = reportHistory
        this.loadingReportHistoryStatus = ''
      }),

      _reportHistoryFailed: action((error) => {
        this.loadingReportHistoryStatus = 'failed'
      }),

      fetchReportDeliveryDetails: action((reportId: number) => {
        this.loadingReportDeliveryDetails = 'loading'
        return reportsService
          .fetchReportDeliveryDetails(reportId)
          .then(this._reportDeliveryDetailsSuccess, this._reportDeliveryDetailsFailed)
      }),

      _reportDeliveryDetailsSuccess: action((reportDeliveryDetails: ReportDetails) => {
        this.reportDeliveryDetails = reportDeliveryDetails
        this.loadingReportDeliveryDetails = ''
      }),

      _reportDeliveryDetailsFailed: action((error) => {
        this.loadingReportDeliveryDetails = 'failed'
      }),

      retryReport: action((reportId: number, definition_id: number) => {
        try {
          reportsService
            .retryReport(reportId)
            .then(() => reportsService.fetchReportHistory(definition_id))
            .then(this._reportHistorySuccess)
        } catch (error) {
          console.log('Error while retrying report generation', error)
        }
      }),

      retryFailedReportDeliveries: action((reportId: number) => {
        try {
          reportsService
            .retryFailedReportDeliveries(reportId)
            .catch((error) => console.log('Error while delivering failed report parts', error))
            .then(() => reportsService.fetchReportDeliveryDetails(reportId))
            .then(this._reportDeliveryDetailsSuccess)
        } catch (error) {
          console.log('Error while delivering failed report parts', error)
        }
      }),

      retryFailedPartDelivery: action((reportId: number, partId: string) => {
        try {
          reportsService
            .retryFailedPartDelivery(reportId, partId)
            .catch((error) => console.log('Error while delivering failed report part', error))
            .then(() => reportsService.fetchReportDeliveryDetails(reportId))
            .then(this._reportDeliveryDetailsSuccess)
        } catch (error) {
          console.log('Error while delivering failed report part', error)
        }
      }),

      skipFailedPartDelivery: action((reportId: number, partId: string) => {
        try {
          reportsService
            .skipFailedPartDelivery(reportId, partId)
            .catch((error) => console.log('Error while trying to skip failed report part', error))
            .then(() => reportsService.fetchReportDeliveryDetails(reportId))
            .then(this._reportDeliveryDetailsSuccess)
        } catch (error) {
          console.log('Error while delivering failed report part', error)
        }
      }),
    })
  }
}
