import React from 'react'
import { formatLocaleDate } from '../lib/dates'

import ColoredStatus from './ColoredStatus'
import { hasExceededThreshold } from './utils'
import type { ReportHistory } from '../dataTypes'
import { DownloadIcon } from './icons/DownloadIcon'

type Props = {
  reportName: string,
  reportDeliveryDetails: ?ReportHistory,
  isReportProcessing: boolean,
  retryReport: Function,
  retryFailedReportDeliveries: Function,
  onRetry: Function,
}

export class ReportDeliveryDetails extends React.Component<Props> {
  render() {
    if (!this.props.reportDeliveryDetails) {
      return (
        <div>
          <h2>Report doesn't exist</h2>
        </div>
      )
    }
    const { reportName, isReportProcessing, retryReport, retryFailedReportDeliveries, onRetry } = this.props
    const { reportId, fileName, from, to, created, lastEvent, status, reportUrl } = this.props.reportDeliveryDetails

    const isExceededTimeout = hasExceededThreshold(lastEvent)
    const isProcessing =
      isReportProcessing || (['GENERATING', 'GENERATED', 'DELIVERING'].includes(status) && !isExceededTimeout)
    const isGenerated = !['GENERATING', 'GENERATION_FAILED'].includes(status)
    const isFailed =
      ['GENERATION_FAILED', 'DELIVERY_FAILED'].includes(status) ||
      (['GENERATING', 'GENERATED', 'DELIVERING'].includes(status) && isExceededTimeout)
    const isDeliveryFailed = 'DELIVERY_FAILED' === status || ('DELIVERING' === status && isExceededTimeout)

    return (
      <div className="card">
        <h2 className="text-lg p-md">
          {reportName} - {reportId}
        </h2>
        <table className="table">
          <tbody>
            <tr>
              <td>
                FileName: <b>{fileName}</b>
                {!isProcessing && isGenerated && (
                  <a href={reportUrl}>
                    <DownloadIcon className="inline-block" color="var(--colors-green)" size="25" />
                  </a>
                )}
              </td>
              <td>
                Created: <b>{formatLocaleDate(new Date(created))}</b>
              </td>
            </tr>
            <tr>
              <td>
                From: <b>{formatLocaleDate(new Date(from))}</b>
              </td>
              <td>
                To: <b>{formatLocaleDate(new Date(to))}</b>
              </td>
            </tr>
            <tr>
              <td>
                Last event: <b>{lastEvent && formatLocaleDate(new Date(lastEvent))}</b>
              </td>
              <td>
                Last status: {''}
                <b>
                  <ColoredStatus status={status} exceededTimeout={hasExceededThreshold(lastEvent)} />
                </b>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                Actions:
                {isProcessing && <div className="loadingSpinner" />}
                {!isProcessing && isFailed && (
                  <button
                    className="btn btn-primary inline-block ml-sm"
                    onClick={() => {
                      onRetry()
                      retryReport(reportId)
                    }}
                  >
                    Retry Report
                  </button>
                )}
                {!isProcessing && isDeliveryFailed && (
                  <button
                    className="btn btn-primary inline-block ml-sm"
                    onClick={() => {
                      onRetry()
                      retryFailedReportDeliveries(reportId)
                    }}
                  >
                    Retry Failed Deliveries
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default ReportDeliveryDetails
