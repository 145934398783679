import { useParams } from 'react-router-dom'
import type { ReportsDataStore } from '../stores'
import ReportDeliveryDetailPage from './ReportDeliveryDetailPage'
import { inject, observer } from 'mobx-react'

type Props = {
  reportsDataStore: ReportsDataStore,
}

const ReportDeliveryDetailPageWrapper = (props: Props) => {
  const {
    reports,
    fetchReportDeliveryDetails,
    loadingReportDeliveryDetails,
    reportDeliveryDetails,
    retryReport,
    retryFailedReportDeliveries,
    retryFailedPartDelivery,
    skipFailedPartDelivery,
  } = props.reportsDataStore
  const { definitionId, reportId } = useParams()
  const reportDefinition = reports.find((report) => report.id.toString() === definitionId)
  if (!reportDefinition) {
    return (
      <div className="centerAbsolute">
        <div className="alert alert-warning">This report does not exist.</div>
      </div>
    )
  }
  return (
    <ReportDeliveryDetailPage
      reportId={reportId}
      reportDefinition={reportDefinition}
      fetchReportDeliveryDetails={fetchReportDeliveryDetails}
      reportDeliveryDetails={reportDeliveryDetails}
      retryReport={retryReport}
      retryFailedReportDeliveries={retryFailedReportDeliveries}
      retryFailedPartDelivery={retryFailedPartDelivery}
      skipFailedPartDelivery={skipFailedPartDelivery}
      loadingReportDeliveryDetails={loadingReportDeliveryDetails}
    />
  )
}

export default inject('reportsDataStore')(observer(ReportDeliveryDetailPageWrapper))
